<template>
	<div class="forgetContainer">
		<div class="register">
			<a-row class="titleRow">
				<span class="title">
					{{ title }}
					<img :src="logoUrl" />
				</span>
			</a-row>
			<a-row class="welcome">
				我们每个人都会遇到忘记密码的情况，我们依然为您提供服务
			</a-row>
			<a-row class="registerText">修改密码</a-row>
			<a-form-model ref="forgetPasswordForm" :model="forgetPasswordForm" :rules="rules">
				<a-form-model-item prop="phone" :whitespace="true">
					<a-input v-model="forgetPasswordForm.phone" placeholder="请输入您的手机号">
						<a-icon slot="prefix" type="user" />
					</a-input>
				</a-form-model-item>
				<a-form-model-item prop="loginPass" :whitespace="true">
					<a-input-password v-model="forgetPasswordForm.loginPass" placeholder="请输入您的密码">
						<a-icon slot="prefix" type="lock" />
					</a-input-password>
				</a-form-model-item>
				<a-form-model-item prop="confirmPass" :whitespace="true">
					<a-input-password v-model="forgetPasswordForm.confirmPass" placeholder="请输入您的确认密码">
						<a-icon slot="prefix" type="lock" />
					</a-input-password>
				</a-form-model-item>
				<a-form-model-item prop="userPhoneSms" :whitespace="true">
					<a-input-group compact>
						<a-input class=" userPhoneSmsInput" v-model="forgetPasswordForm.userPhoneSms" placeholder="请输入您的验证码"></a-input>
						<a-button type="primary" :disabled="getSmscodeFlag" class="getCode" @click="getSmsCount">{{ smsCount }} {{ getSmscodeFlag ? '秒' : '' }}</a-button>
					</a-input-group>
				</a-form-model-item>
				<a-form-model-item class="submit">
					<a-button type="primary" class="button" @click="back">返回</a-button>
					<a-button type="primary" class="button" @click="submit" :loading="confirmLoading">确定</a-button>
				</a-form-model-item>
			</a-form-model>
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	data() {
		const _this = this;
		let chekckPhone = (rule, value, callback) => {
			if (value && value != '') {
				let reg = new RegExp(/^1[3456789]\d{9}$/);
				if (!reg.test(value)) {
					callback('请输入正确的手机号码');
				} else {
					callback();
				}
			} else {
				callback(new Error('请输入手机号码'));
			}
		};
		let chekckLoginPass = (rule, value, callback) => {
			if (value && value != '') {
				let reg = new RegExp(/^.*(?=.{8,20})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/);
				if (!reg.test(value)) {
					callback('请输入8至20个字符并包含数字、大小写字母组合的密码！');
				} else {
					callback();
				}
			} else {
				callback(new Error('请输入密码'));
			}
		};
		let chekckConfirmPassword = (rule, value, callback) => {
			if (value && value != '') {
				if (value != _this.forgetPasswordForm.loginPass) {
					callback('两次密码不一致，请重新输入');
				} else {
					callback();
				}
			} else {
				callback(new Error('请输入确认密码'));
			}
		};
		return {
			title: '智广宝-鸿星智能广告服务平台',
			logoUrl: 'https://resources.xbov.cn/img/C_logo.jpg',
			smsCount: '获取验证码',
			getSmscodeFlag: false,
			confirmLoading: false,
			forgetPasswordForm: {
				phone: undefined,
				loginPass: undefined,
				confirmPass: undefined,
				userPhoneSms: undefined,
			},
			rules: {
				phone: [{ required: true, validator: chekckPhone, trigger: 'change' }],
				loginPass: [{ required: true, validator: chekckLoginPass, trigger: 'change' }],
				confirmPass: [{ required: true, validator: chekckConfirmPassword, trigger: 'change' }],
				userPhoneSms: [{ required: true, message: '请输入手机验证码', trigger: 'change' }],
			},
		};
	},
	created() {
		let domainInfo = this.$store.getters.domainInfo;
		if (domainInfo.domainName) {
			this.title = domainInfo.title;
			this.logoUrl = process.env.VUE_APP_RESOURCES_HOST + domainInfo.logoUrl;
		}
	},
	methods: {
		getSmsCount() {
			const _this = this;
			_this.$refs.forgetPasswordForm.validateField('phone', err => {
				if (err == '') {
					this.$http.get('/login/smsSendCode?phone=' + this.forgetPasswordForm.phone + '&type=2').then(res => {
						if (res.rescode == 200) {
							_this.$message.success(res.msg);
							_this.getSmscodeFlag = true;
							_this.smsCount = 60;
							let timer = setInterval(() => {
								_this.smsCount -= 1;
								if (_this.smsCount == 0) {
									clearInterval(timer);
									_this.smsCount = '获取验证码';
									_this.getSmscodeFlag = false;
								}
							}, 1000);
						} else {
							if (res.msg == '用户名输入错误！') {
								_this.$message.error('该手机号未注册');
							} else {
								_this.$message.error(res.msg);
							}
						}
					});
				}
			});
		},
		submit() {
			const _this = this;
			this.$refs.forgetPasswordForm.validate(valid => {
				if (valid) {
					if (_this.forgetPasswordForm.userPhoneSms.length != 6) {
						_this.$message.warning('请先输入6位数的验证码');
						return;
					}
					if (!_this.$CommonUtil.numTest(_this.forgetPasswordForm.userPhoneSms)) {
						_this.$message.warning('验证码错误!');
						return;
					}
					let params = {
						phone: _this.forgetPasswordForm.phone,
						code: _this.forgetPasswordForm.userPhoneSms,
						passWord: _this.forgetPasswordForm.loginPass,
					};
					_this.confirmLoading = true;
					_this.$http
						.post('/usertoken/resetPassword', params)
						.then(res => {
							if (res.rescode == 200) {
								_this.confirmLoading = false;
								_this.$message.success('密码修改成功');
								_this.$router.push({ name: 'Login' });
							} else {
								_this.$message.warning(res.msg);
								_this.confirmLoading = false;
							}
						})
						.catch(error => {
							_this.confirmLoading = false;
							console.log(error);
						});
				}
			});
		},
		//返回
		back() {
			this.$router.push({ name: 'Login' });
		},
	},
};
</script>

<style lang="less" scoped>
.forgetContainer {
	background: url('https://resources.xbov.cn/img/forgetPassword_bgc.png') no-repeat;
	background-size: 100% 100%;
	position: relative;

	.register {
		position: absolute;
		right: 650px;
		top: 100px;
		width: 600px;
		height: 750px;
		// box-shadow: 3px 2px 5px #888888;
		padding: 50px;
		.titleRow {
			text-align: center;
			margin-top: 10px;
			.title {
				font-size: 27px;
				font-weight: 700;
				margin-left: 60px;
				position: relative;
				img {
					position: absolute;
					top: -5px;
					left: -60px;
					width: 50px;
					height: 50px;
					// margin-top: 15px;
				}
			}
		}
		.welcome {
			text-align: center;
			color: #999999;
			margin-top: 30px;
			font-size: 18px;
		}
		.registerText {
			font-size: 20px;
			font-weight: 700;
			margin: 40px 0 20px;
		}
		.formInput {
			height: 50px;
			margin-top: 20px;
			font-size: 18px;
		}
		.userPhoneSmsInput {
			width: 70%;
		}
		.getCode {
			height: 50px;
			margin-left: 20px;
			font-size: 18px;
			width: 26%;
		}
		.checkBox {
			margin-top: 20px;
		}
		.checkBoxText {
			margin-left: 10px;
		}
		.submit {
			width: 100%;
			height: 50px;

			.button {
				width: 40%;
				height: 50px;
				margin-left: 32px;
				font-size: 20px;
			}
		}
		.footerText {
			margin-top: 20px;
			.footer {
				font-size: 18px;
			}
			.floatR {
				float: right;
			}
		}
	}
}
</style>
